import RequestSender = Logic.RequestSender;
import ContextMenu = Entity.View.Menu.ContextMenu;
import DeviceNode = Entity.Map.Marker.Node.DeviceNode;
import URIParser = Logic.URIParser;
import URIPolygonParser = Logic.URIPolygonParser;
import URILineParser = Logic.URILineParser;
import URICenterParser = Logic.URICenterParser;
import URIMessageParser = Logic.URIMessageParser;

declare var config;

class App {
    protected mapCanvas;

    protected serviceContainer: Control.ServiceContainer = null;
    protected _entityContainer: Control.EntityContainer = null;
    protected static instance: App = null;
    protected static apiKey = null;

    //test:
    protected time0: number = performance.now();
    protected time0part: number = performance.now();

    protected startTimeTest() {
        this.time0 = performance.now();
        this.time0part = performance.now();
    }

    protected test(desc: string) {
        let partTime: number = Math.round((performance.now() - this.time0part) * 100) / 100;
        let totalTime: number = Math.round((performance.now() - this.time0) * 100) / 100;
        console.dir(desc + ': ' + partTime + ' / ' + totalTime + ' ms');
        this.time0part = performance.now();
    }

    //test - end

    get entityContainer(): Control.EntityContainer {
        return this._entityContainer;
    }

    get service(): Control.ServiceContainer {
        return this.serviceContainer;
    }

    public static getApiKey(): string {
        return this.apiKey;
    }

    public static getInstance(): App {
        if (App.instance == null)
            throw new Error('Cannot get the instance of App: instance hasn\'t been created yet');

        return App.instance;
    }

    constructor(apiKey?: string) {
        App.apiKey = apiKey;
        App.instance = this;
    }

    //<editor-fold desc="Bootstrap and initialize">
    public run(mapCanvas) {
        try {
            this.initBasicModules(mapCanvas);
            this.service.initMap(mapCanvas);
            this.service.loader.show('Ładowanie mapy');


            //moduły mapy:
            this.service.initMenus();
            this.service.initInfoWindow();
            this.service.initWindowContainer();
            this.service.initOverlay();
            this.service.initDrawingManager();
            this.service.initDirectionsRenderer();
            this.service.initRangeManager();
            this.service.initSettings((): void => {
                this.fillMap();
                this.parseURI();
            }, (): void => {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Aby korzystać z mapy, należy się zalogować');
                this.service.loader.dismiss();
                alert.show();
            });
            //moduły mapy - end
        }
        catch (e) {
            console.dir(e);
            console.error('Caught exception in App constructor: ' + e.message)
        }
    }

    public initContainers(): void {
        this.serviceContainer = new Control.ServiceContainer();
        this._entityContainer = new Control.EntityContainer();
    }

    public initBasicModules(mapCanvas): void {
        this.initContainers();
        this.service.initCallbackContainer();
        this.service.initInputManager();
        this.service.initLoader();
    }


    protected parseURI(): void {
        let polygonParser: URIPolygonParser = new URIPolygonParser();
        let lineParser: URILineParser = new URILineParser();
        let centerParser: URICenterParser = new URICenterParser();
        let messageParser: URIMessageParser = new URIMessageParser();

        this._entityContainer.polygons.push(polygonParser.createPolygon());
        this._entityContainer.lines.push(lineParser.createLine());
        centerParser.centerMap(this.service.map);

        messageParser.showErrorAlert();

    }

    //</editor-fold>


    //<editor-fold desc="Fill the map">
    /**
     * Pobieranie danych z bazy danych i wypełnianie nimi mapy
     */
    protected fillMap() {
        let requestSender: Logic.RequestSender = new Logic.RequestSender();
        requestSender.sendRequest('GetAll', null, (action: string, fields: Object, response: any) => {
            this.startTimeTest();

            if (requestSender.isError()) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                alert.show();
                this.postFill();
                return;
            }

            let nodes = JSON.parse(response.nodes);
            let links = JSON.parse(response.links);
            let clients = JSON.parse(response.clients);
            let regions = JSON.parse(response.regions);
            let categories = JSON.parse(response.categories);
            let types = JSON.parse(response.types);
            let linkTypes = JSON.parse(response.linkTypes);
            let range = JSON.parse(response.range);
            let offers = JSON.parse(response.offers);

            this.test('parsing');

            this.populateRegions(regions);
            this.test('populate regions');
            this.populateCategories(categories);
            this.test('populate categories');
            this.populateTypes(types);
            this.test('populate types');
            this.populateLinkTypes(linkTypes);
            this.test('populate linkTypes');
            this.populateOffers(offers);
            this.test('populate offers');
            this.populateRange(range);
            this.test('populate range');

            this.populateNodes(nodes, Factory.NodeSupertype.DEVICE);
            this.test('populate nodes');
            this.populateNodes(clients, Factory.NodeSupertype.CLIENT);
            this.test('populate clients');
            this.generateClientLinks();
            this.test('populate client links');
            this.generateDeviceLinks(links);
            this.test('populate links');

            /*
            this.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.MARKER, 'rightclick', (e: Control.CallbackContainer.Event): boolean => {
                if(e.target instanceof Entity.Map.Marker.Node.DeviceNode)
                    if(e.target.isSubnode())
                        this.service.contextMenu.show(e.target, Action.UserActionEvent.CreateForCallbackEvent(e));
                    else
                        this.service.contextMenu.show(e.target, Action.UserActionEvent.CreateForCallbackEvent(e));
                else if(e.target instanceof Entity.Map.Marker.Node.ClientNode)
                    this.service.contextMenu.show(e.target, Action.UserActionEvent.CreateForCallbackEvent(e));
                return true;
            });*/

            this.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.MARKER, 'drag', (e: Control.CallbackContainer.Event): boolean => {
                e.target.updatePolylinesMarkers(e);
                return true;
            });

            this.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.MARKER, 'dragend', (e: Control.CallbackContainer.Event): boolean => {
                e.target.toggleDraggable();
                return true;
            });

            this.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.LINK, 'rightclick', (e: Control.CallbackContainer.Event): boolean => {
                this.service.contextMenu.show(e.target, Action.UserActionEvent.CreateForCallbackEvent(e));
                return true;
            });


            this.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.LINK, 'click', (e: Control.CallbackContainer.Event): boolean => {
                let link = <Entity.Map.Shape.Link.Link>e.target;

                if (!link)
                    return;

                let node1: Entity.Map.Marker.Node.Node = link.node1 as Entity.Map.Marker.Node.Node;
                let node2: Entity.Map.Marker.Node.Node = link.node2 as Entity.Map.Marker.Node.Node;
                let marker1: google.maps.Marker = node1.marker;
                let marker2: google.maps.Marker = node2.marker;

                marker1.setAnimation(google.maps.Animation.BOUNCE);
                marker2.setAnimation(google.maps.Animation.BOUNCE);

                setTimeout(function () {
                    marker1.setAnimation(null);
                    marker2.setAnimation(null);
                }, 700);

                this.service.infoWindow.setContent(link.getDetails());
                this.service.infoWindow.setPosition(e.params.latLng);
                this.service.infoWindow.open(this.service.map, link);

                return true;
            });

            this.test('push callbacks');

            requestSender.sendRequest('GetVehicleGPS', null, (action: string, fields: Object, response: any) => {
                if (!requestSender.isError()) {
                    this.populateVehicleGPS(JSON.parse(response));
                }
                this.postFill();
            });

            this.test('settings request');
        });
    }

    protected postFill() {
        this.service.initMarkerClusterer(this._entityContainer.markers);
        this.service.mainMenu.populateMenu();
        this.service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.APP, new Control.CallbackContainer.Event('ready', this, {}));
        this.service.loader.dismiss();
    }

    public populateRegions(data): void {
        for (let key in data) {
            let regionRowData = data[key];
            let region: Entity.Map.Type.Region = new Entity.Map.Type.Region(regionRowData);
            this._entityContainer.regions[region.id] = region;
        }
    }

    public populateCategories(data): void {
        for (let key in data) {
            let categoryRowData = data[key];
            let category: Entity.Map.Type.Category = new Entity.Map.Type.Category(categoryRowData);
            this._entityContainer.categories[category.id] = category;
        }
    }

    public populateTypes(data): void {
        for (let key in data) {
            let typeRowData = data[key];
            let type: Entity.Map.Type.Type = new Entity.Map.Type.Type(typeRowData);
            this._entityContainer.types[type.id] = type;
        }
    }

    public populateLinkTypes(data): void {
        for (let key in data) {
            let typeRowData = data[key];
            let linkType: Entity.Map.Type.LinkType = new Entity.Map.Type.LinkType(typeRowData);
            this._entityContainer.linkTypes[linkType.id] = linkType;
        }
    }

    public populateOffers(data): void {
        for (let key in data) {
            let offerRowData = data[key];
            if (!offerRowData)
                continue;

            let offer: Entity.Map.NetworkRange.Offer = new Entity.Map.NetworkRange.Offer(offerRowData);
            this._entityContainer.offers[offer.id] = offer;
        }
    }

    public populateRange(data): void {
        for (let key in data) {
            let rangeRowData = data[key];
            if (!rangeRowData)
                continue;

            Factory.RangePolygonFactory.createExistingRangePolygon(rangeRowData);
        }
    }

    public populateVehicleGPS(data): void {
        let path: Entity.Map.Marker.VehicleGPS.GPS[] = [];
        for (let key in data) {
            let vehicleGPSRowData = data[key];
            if (!vehicleGPSRowData)
                continue;

            let gps: Entity.Map.Marker.VehicleGPS.GPS = new Entity.Map.Marker.VehicleGPS.GPS(vehicleGPSRowData);
            path.unshift(gps);
        }

        let vehicle: Entity.Map.Marker.VehicleGPS.Vehicle = new Entity.Map.Marker.VehicleGPS.Vehicle(0, {}, this.service.map, path);
        this._entityContainer.vehicles.push(vehicle);
    }

    /**
     * Tworzenie i nanoszenie na mapę węzłów
     * @param data - dane węzłów
     * @param clientsOrDevices - czy urządzenia czy klienci
     */


    public populateNodes(data, supertype): void {
        for (let prop in data) {
            if (data.hasOwnProperty(prop)) {
                let currentNode = data[prop];
                currentNode = Factory.NodeFactory.createNode(supertype, currentNode['id'], currentNode['gps'].split(',')[0], currentNode['gps'].split(',')[1], data[prop], this.service.map);

                if (supertype == Factory.NodeSupertype.DEVICE)
                    this._entityContainer.devices[currentNode.id] = currentNode;
                else if (supertype == Factory.NodeSupertype.CLIENT)
                    this._entityContainer.clients[currentNode.id] = currentNode;

                this._entityContainer.markers.push(currentNode.marker);
            }
        }
    }

    /**
     * Tworzenie linków między węzłami (tworzenie instancji połączeń i linii łączących markery)
     * @param links
     */
    public generateDeviceLinks(links) {
        for (let prop in links) {
            if (links.hasOwnProperty(prop) && this._entityContainer.devices[links[prop].wezel1_id] && this._entityContainer.devices[links[prop].wezel2_id]) {
                this.generateSingleLink(links[prop], this._entityContainer.devices[links[prop].wezel1_id], this._entityContainer.devices[links[prop].wezel2_id]);
            }
        }
    }

    /**
     * Tworzenie linków między węzłami a klientami
     * @param links
     */
    public generateClientLinks() {
        for (let client of this._entityContainer.clients) {
            if (!client)
                continue;

            if (client.node_id && client.node_id > 0) {
                let deviceNode: Entity.Map.Marker.Node.DeviceNode = this._entityContainer.findNode(client.node_id.toString()) as Entity.Map.Marker.Node.DeviceNode;
                if (!deviceNode)
                    continue;

                client.link = new Entity.Map.Shape.Link.ClientLink(client, deviceNode, this.service.map);
                client.link.determineVisibility();
            }
        }
    }

    /**
     * Tworzenie jednego połączenia na podstawie podanych węzłów
     * @param linkData
     * @param nodes
     */
    public generateSingleLink(linkData: Object, node1: Entity.Map.Marker.Node.Node, node2: Entity.Map.Marker.Node.Node) {
        let link: Entity.Map.Shape.Link.Link = new Entity.Map.Shape.Link.Link(linkData, node1, node2, this.service.map);
        this._entityContainer.links[linkData['id']] = link;
        this._entityContainer.polylines.push(link.polyline);
    }

    //</editor-fold>
}