/**
 * Created by Mateusz Lipowski on 21.09.2016.
 */

namespace Logic {
    import LatLngBounds = google.maps.LatLngBounds;
    type addressCallback = (address: string) => void;
    type latLngCallback = (results: Logic.GeocodeResult[]) => void;

    class ApiRequestType {
        public static ADDRESS = 'address';
        public static LATLNG  = 'latlng';
    }

    export class Geocode {
        public coordinatesRegExpSimple: RegExp = /(\-?\d+\.\d+),\s*(\-?\d+\.\d+)/;
        protected lat: number;
        protected lng: number;
        protected address: string;
        protected apiBaseURL: string     = 'https://maps.googleapis.com/maps/api/geocode/json';

        public getAddress(lat: number, lng: number, callback: addressCallback) {
            this.lat = lat;
            this.lng = lng;
            let url  = this.generateURLLatLng();
            this.sendApiRequest(url, callback, ApiRequestType.ADDRESS);
        };

        public getLatLng(address: string, callback: latLngCallback) {
            this.address = address;
            let url      = this.generateURLAddress();
            this.sendApiRequest(url, callback, ApiRequestType.LATLNG);
        };

        protected generateURLLatLng() {
            return this.apiBaseURL + '?latlng=' + this.lat + ',' + this.lng;
        }

        protected generateURLAddress() {
            return this.apiBaseURL + '?address=' + encodeURIComponent(this.address);
        }

        protected sendApiRequest(url: string, callback: addressCallback | latLngCallback, apiRequestType: ApiRequestType) {
            let request = new XMLHttpRequest();
            request.open('GET', url + "&key=" + App.getApiKey(), true);

            request.onreadystatechange = () => {
                if (request.readyState == 4 && request.status == 200) {
                    let response = JSON.parse(request.responseText);

                    if (apiRequestType == ApiRequestType.ADDRESS) {
                        let aCallback = callback as addressCallback;
                        let address   = null;

                        if (response.results && response.results.length > 0) {
                            let formattedAddress = response.results[0].formatted_address;

                            let adressFormatRegexp = new RegExp('^([^,]*), ([0-9]{2}-[0-9]{3} )?([^,]*), (.*)$');
                            let postalCodeRegExp   = new RegExp('[0-9]{2}-[0-9]{3}');

                            let matches = adressFormatRegexp.exec(formattedAddress);
                            if (matches) {
                                let streetAndNumber = matches[1];
                                let postalCode      = (matches[2] ? matches[2].slice(0, -1) : null);
                                let city            = matches[3];
                                let country         = matches[4];

                                if (!postalCode)
                                    postalCode = response.results[4].address_components[0].long_name;

                                if (!postalCodeRegExp.exec(postalCode)) {
                                    postalCode = '??-???';
                                }

                                address = postalCode + ' ' + city + ', ul. ' + streetAndNumber;
                            }
                            else {
                                address = 'Nieznana lokalizacja';
                            }

                        } else {
                            address = "Nieznana lokalizacja";
                        }

                        aCallback(address);
                    }
                    else if (apiRequestType == ApiRequestType.LATLNG) {
                        let lCallback                      = callback as latLngCallback;
                        let results: Logic.GeocodeResult[] = [];

                        for (let i = 0; i < response.results.length; i++) {
                            let result: Logic.GeocodeResult = new Logic.GeocodeResult(this.address, response.results[i]);
                            results.push(result);
                        }

                        lCallback(results);
                    }
                }
            };

            request.send(null);
        }
    }
}